const changeStyles = html => {
  const liRex = /<li[^>]*>(.*?)<\/li>/gi;
  let li;
  let returnHtml = html;
  // eslint-disable-next-line no-cond-assign
  while ((li = liRex.exec(returnHtml))) {
    const splitedLi = li[1].split(' ');
    splitedLi[0] = `<h3 class="highlight">${splitedLi[0]}</h3>`;
    const modified = splitedLi.join(' ');
    returnHtml = returnHtml.replace(li[1], modified);
  }
  return returnHtml;
};

export default changeStyles;
